export const SendOtpMutation= (phone , isDev) =>{
    return `
    mutation{adminLogin(Mobile:"${phone}" , isDevEnvironment : ${isDev} ){
      _id
    }}`
  };

 
  export const ConfirmOtpMutation=(data , isDev)=>{
    const otp =Number(data.otp)


    return` mutation{
        confirmOtp(otp:${otp},id:"${data.UserId}",isDevEnvironment : ${isDev} , deviceToken:"jhjhjhjhjh")
      }`
  }
  