// importing libraries
import { createStore, applyMiddleware } from 'redux'; 
import thunk from 'redux-thunk'; 
import rootReducer from '../reducer/index'; 

// creating the redux store
const store = createStore(rootReducer,applyMiddleware(thunk));

export default store

