import React from 'react'
import { CSVLink } from 'react-csv'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { exportData } from '../../Redux/action/user/userAction';

export const ExportReactCSV = (props) => {
    console.log("pppppppppppppp", props)

    const [data, setData] = React.useState([]);
    const dispatch = useDispatch();
    React.useEffect(async () => {
        var data = {
            pageNumber: props?.pageNumber ? props?.pageNumber : 1,
        }
        const data2 = await dispatch(exportData(data))
        setData(data2);
    }, [])


    const arr = [];
    const leadsArr = [];
    const subscriptionArr = [];
    data.forEach(element => {

        const data = {
            Id: element?._id,
            Name: element?.personalprofile?.fullName,
            Gender: element?.personalprofile?.gender,
            BloodGroup: element?.personalprofile?.bloodGroup,
            Email: element?.personalprofile?.email,
            Mobile: element?.Mobile,
            Age: element?.personalprofile?.age,
            Address: element?.address?.presentAddress,
            Active: element?.Active,
            Verified: element?.Verified,
            createdAt: moment(element?.createdAt).format('YY-DD-MM-hh:mm:ss:a'),
            workDivision: element?.workDivision?.name,
            EmergencyContactCount: element?.EmergencyContact.length,
            ContactName: element?.EmergencyContact[0]?.fullName ? element?.EmergencyContact[0]?.fullName : null,
            Relation: element?.EmergencyContact[0]?.relation ? element?.EmergencyContact[0]?.relation : null,
            ContactNumber: element?.EmergencyContact[0]?.phone ? element?.EmergencyContact[0]?.phone : null,

            ContactName2: element?.EmergencyContact[1]?.fullName ? element?.EmergencyContact[1]?.fullName : null,
            Relation2: element?.EmergencyContact[1]?.relation ? element?.EmergencyContact[1]?.relation : null,
            ContactNumber2: element?.EmergencyContact[1]?.phone ? element?.EmergencyContact[1]?.phone : null,

            ContactName3: element?.EmergencyContact[2]?.fullName ? element?.EmergencyContact[2]?.fullName : null,
            Relation3: element?.EmergencyContact[2]?.relation ? element?.EmergencyContact[2]?.relation : null,
            ContactNumber3: element?.EmergencyContact[2]?.phone ? element?.EmergencyContact[2]?.phone : null,

            ContactName4: element?.EmergencyContact[3]?.fullName ? element?.EmergencyContact[3]?.fullName : null,
            Relation4: element?.EmergencyContact[3]?.relation ? element?.EmergencyContact[3]?.relation : null,
            ContactNumber4: element?.EmergencyContact[3]?.phone ? element?.EmergencyContact[3]?.phone : null
        }
        arr.push(data)

    });
    props?.leadsData?.forEach(element => {
        console.log("elementelementelement", element)
        const data = {
            _id: element?._id,
            name: element?.name,
            mobile: element?.mobile,
            email: element?.email,
            planClicked: element?.planClicked,
            uniqueId: element?.uniqueId,
            utm_campaign: element?.UTM?.utm_campaign,
            utm_content: element?.UTM?.utm_content,
            utm_medium: element?.UTM?.utm_medium,
            utm_source: element?.UTM?.utm_source,
            utm_term: element?.UTM?.utm_term,
        }
        leadsArr.push(data);
    })
    props?.subcriptionData?.forEach(element => {
        const data = {
            userId: element?.personalInformation?.userId,
            Mobile: element?.personalInformation?.fullName,
            fullName: element?.usersDetail?.Mobile,
            email: element?.personalInformation?.email,
            age: element?.personalInformation?.age,
            bloodGroup: element?.personalInformation?.bloodGroup,
            gender: element?.personalInformation?.gender,
            Subscription_Amount: element?.Subscription_Amount,
            Subscription_Plan_Name: element?.Subscription_Plan_Name,
            access_FullServices: element?.access_FullServices,
            access_monthly: element?.access_monthly,
            access_oneTime_amberAlert: element?.access_oneTime_amberAlert,
            typeOfPayment_oneTime: element?.typeOfPayment_oneTime,
            razorpay_payment_id_oneTime: element?.razorpay_payment_id_oneTime,
            access_yearly: element?.access_yearly,
            couponId: element?.couponId,
            createdAt: element?.createdAt,
            start_date: element?.start_date,
            end_date: element?.end_date,
            razorpay_payment_id_lifeTime: element?.razorpay_payment_id_lifeTime,
            typeOfPayment_subscription: element?.typeOfPayment_subscription,
            razorpay_payment_id_subscription: element?.razorpay_payment_id_subscription,
            razorpay_signature: element?.razorpay_signature,
            subscription_id: element?.subscription_id,
        }
        subscriptionArr.push(data)
    })


    return (
        <Button >
            {
                props.fileName === "helpyFinder" &&
                <CSVLink style={{ color: 'white' }} data={arr} filename={props.fileName}>Export To Excel</CSVLink>
            }
            {
                props.fileName === "helpyFinderLeadsData" &&
                <CSVLink style={{ color: 'white' }} data={leadsArr} filename={props.fileName}>Export To Excel</CSVLink>
            }
            {
                props.fileName === "helpyFinderSubscriptionData" &&
                <CSVLink style={{ color: 'white' }} data={subscriptionArr} filename={props.fileName}>Export To Excel</CSVLink>
            }
        </Button>
    )
}