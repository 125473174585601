import React from 'react';
import ReactPaginate from 'react-paginate';

const Paginate = ({  handlePageClick, pageCount, forcePage }) => {
  return (
    <ReactPaginate
      breakLabel="..."
      className="table-pagination-home"
      nextLabel=" >"
      onPageChange={ handlePageClick}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel="< "
      activeClassName="active"  
      forcePage={forcePage}
    />
  );
};

export default Paginate;

